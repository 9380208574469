



































































import Vue from "vue";
import { postAPIUrlEncoded, setStorageData, getStorageData, getAPI } from "../../util";

export default Vue.extend({
	data() {
		return {
			showPassword: false,
			username: null,
			password: "",
			loading: false,
			error: null,
		};
	},
	methods: {
		loginClicked: function () {
			this.loading = true;

			// Checking user authentication
			postAPIUrlEncoded(
			"login",
			"",
			"&username=" + this.username + "&password=" + this.password
			).then((response) => {
				// console.log("loginClicked() - response: ", response);
				this.loading = false;
				if(response.success) {
					// Store User info in local storage
					setStorageData('user', response.data);

					// Samee - routing to the dashboard in case of succcessful authentication
					this.$router.replace("/");
				}
				else {
					this.error = response.error;
				}
				
			});
		},
	},
	computed: {
		getMdiEye() {
			return this.showPassword ? 'mdi-eye-cirle' : 'mdi-eye-off';
		}
	}
	});
