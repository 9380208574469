















// import { AssignmentDownload } from "@/data/assignment/Assignment";
import Vue from "vue";
export default Vue.extend({
  props: {
    download: {
      type: String,
      required: true,
    },
	btnLabel: {
		type: String,
		required: false,
	}
  },
  computed: {
	getBtnLabel() {
		console.log("this.btnLabel: ", this.btnLabel);
		return this.btnLabel != null ? this.btnLabel : "Download";
	},
  }
});
