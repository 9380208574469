export enum AssignmentStatus {
  Upcoming = "Upcoming",
  Pending = "Pending",
  Overdue = "Overdue",
  Submitted = "Submitted",
  SubmittedLate = "SubmittedLate",
}

export enum AssignmentDownloadType {
  Pdf = "Pdf",
  Doc = "Doc",
}

export class AssignmentDownload {
  public type: AssignmentDownloadType;
  public url: URL;

  public constructor(type: AssignmentDownloadType, url: URL) {
    this.type = type;
    this.url = url;
  }
}

export default class Assignment {
  public subject: string;
  public title: string;
  public description: string;
  public dueDate: Date;
  public status: AssignmentStatus;
  public download?: AssignmentDownload;

  public constructor(
    subject: string,
    title: string,
    description: string,
    dueDate: Date,
    status: AssignmentStatus,
    download?: AssignmentDownload
  ) {
    this.subject = subject;
    this.title = title;
    this.description = description;
    this.dueDate = dueDate;
    this.status = status;
    this.download = download;
  }
}
