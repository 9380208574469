




























import Vue from "vue";
// import Conversation from "@/data/Conversation";
import {getStorageData} from "../../util";

export default Vue.extend({
	props: {
		id: {
			type: String,
			required: true,
		},
		conversation: {
			type: Object,
			required: true,
		},
	},
	methods: {
		getDateString() {
			/*
			return this.conversation.date.toLocaleDateString("en-US", {
				year: "numeric",
				month: "short",
				day: "numeric",
			});
			*/
			return this.conversation.date;
		},
		getOtherParticipant() {
			let contactName = this.conversation.contactName;
			return {
				name: "Jane Doe",
				// profilePictureUrl: "https://randomuser.me/api/portraits/lego/4.jpg",
				profilePictureUrl: "https://ui-avatars.com/api/?name=" + contactName.split(' ').join('+'),
			};
		},
		onClick() {
			if(this.conversation.numUnreadMessages > 0) {
				this.$emit('conversation-opened', this.conversation.numUnreadMessages);
			}
			this.$router.push(`/correspondence/${this.id}`);
		},
		
	},
	computed: {
		bgColorClass() {
			// return this.conversation.dateEnded != null ? 'highlighted' : '';
			return this.conversation.numUnreadMessages > 0 ? 'highlighted' : '';
		},
		
		getContactName() {
			const user = getStorageData('user');
			return user['role'] == 'parent' ? this.conversation.contactName : (this.conversation.studentName != null ? this.conversation.studentName : this.conversation.contactName);
		}
	},
});
