




































































import Vue from "vue";
import Classwork from "./classwork/Classwork.vue";
import Homework from "./homework/Homework.vue";
import { getAPI, getStorageData } from "../../util";

import Assignment, {
  AssignmentDownload,
  AssignmentDownloadType,
  AssignmentStatus,
} from "../../data/assignment/Assignment";

export default Vue.extend({
	components: { Homework },
	data() {
		return {
			tab: this.$route.query.tab,
			bLoaded: false,
			autoRefresh: true,
			interval: null,
			timeInterval: 30000,
			lastId: 0,
			diaryList: [],
			limit: 50,
			offset: 0,
			scrolledToBottom: false,
			loading: true,
			user: null,
			classesList: [],
		};
	},
	beforeMount() {
		const user = getStorageData('user');
		this.user = user;
		
		if(this.user.role != 'parent') {
			if(this.classesList.length == 0) {
				const year = 2021;
				getAPI("teachersClassList", user["accessToken"], {"year": year}).then((response: any) => {
					this.classesList = response['data'];
				});
			}
		}
				
		// console.log("Diary - beforeMount() called - bLoaded: ", this.bLoaded);
		// window.addEventListener('scroll', this.scroll);
		this.refreshData();

		const interval = setInterval(async () => {
			if(this.autoRefresh) { 
				this.refreshData();	  
			}
		}, this.timeInterval);
		this.interval = interval;
	},
	beforeDestroy() {
		// console.log("Diary::beforeDestroy() - ");
		clearInterval(this.interval);
		// window.removeEventListener('scroll', this.scroll);
	},
	props: {
		selectedStudentId: [String, Number],
		classworkList: [Array],
		homeworkList: [Array],
		noticesList: [Array],
	},
	methods: {
		refreshData() {
			// Samee - Check to see if user info exists in local storage
			const user = getStorageData('user');
			// console.log("Diaries - refreshData() called - user info fetched: ", user);
			// Setting tmpLastId
			let tmpLastId = 0;
			/*
			// console.log("this.classworkList[0]: ", this.classworkList[0]);
			if(this.classworkList[0] != null) {
				tmpLastId = this.classworkList[0]['id'];
				if(this.homeworkList[0]['id'] > tmpLastId)
					tmpLastId = this.homeworkList[0]['id'];
			}
			*/
			
			if(user != null) {
				const year = 2021;
				// Fetch diaries list
				const classId = this.classId != undefined ? this.classId : '';
				const endpoint = user["role"] == "parent" ? "diaryList" : "teachersDiaryList";
				getAPI(endpoint, user["accessToken"], {"year": year, "userId": user['id'], "limit": this.limit, "offset": this.offset, "classId": classId}).then((response: any) => {
					this.loading = false;
					let data = JSON.parse(JSON.stringify(response.data));
					if(response['error'] != null) {
						this.$router.replace("/login");
					} else {
						// console.log("Diary::refreshData() - this.selectedStudentId: ", this.selectedStudentId);
						this.$emit('diary-loaded', data);						
					}
				});
			} else {
				this.$router.replace("/login");
			}
		},
		btnReadMoreClicked(pAppUsersNotificationId) {
			// console.log("Diary::btnReadMoreClicked() - pAppUsersNotificationId: ", pAppUsersNotificationId);
			this.$emit('read-more-clicked', pAppUsersNotificationId);
		},
		onClassChanged() {
			const user = getStorageData('user');
			// console.log("class changed to : ", this.classId);
			this.refreshData();
		},
		scroll () {		
			let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight;

			if (bottomOfWindow) {
				this.scrolledToBottom = true; // replace it with your code
				console.log("scrolled to bottomOfWindow");
			}
		},
	},
	computed: {
		numUnreadClassworks() {
			return this.classworkList.filter(row => row.bRead == '0').length;
		},
		numUnreadHomeworks() {
			return this.homeworkList.filter(row => row.bRead == '0').length;
		},
		numUnreadNotices() {
			return this.noticesList.filter(row => row.bRead == '0').length;
		},
	},
	watch: {
		tab(tab) {
			this.$router.replace({ query: { ...this.$route.query, tab } });
		},
	},
});
