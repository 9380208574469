// ToDos - This has to be changed accordingly when deploying

// export const basePath = "testapp"; // change this to app or testapp or whatever!
export const basePath = "app"; // change this to app or testapp or whatever!
// const baseURI = "http://localhost/app/"; // For local deployment
// const baseURI = "https://beaconlightacademy.edu.pk/" + basePath + "/"; // For production deployment
const baseURI = "/" + basePath + "/"; // For production deployment


export function postAPIUrlEncoded(endpoint: string, body: any, queryString = "") {
	const currentTimestamp = (new Date).getTime();
	const postURL = baseURI + "restapi.php?endpoint=" + endpoint + "&rnd=" + currentTimestamp + queryString; // + "&" + 
    return fetch(postURL,
    {
		method: "post",
		// credentials: "include",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			"Connection": "keep-alive",
		},
		body: body,// body: JSON.stringify(body),
    })
    .then((response) => response.json())
    .then((responseData) => {
		// console.log("Calling POST API (Url Encoded) Endpoint '" + endpoint + "' with body: ", body, ", result: ", responseData);
		if(responseData.success == undefined) {
			alert("API Error upon calling endpoint (Url Encoded): " + responseData['error']);
		}
		return responseData;
	})
	.catch(error => {
		console.log("error calling endpoint " + endpoint + ": ", error);
		alert("error calling endpoint " + endpoint + ": " + error);
	});
	return null;
}

export function getAPI(endpoint: string, token: string, params: any = null){
	// To prevent caching we pass in a random param to the API call
	const currentTimestamp = (new Date).getTime();
	let url = baseURI + "restapi.php?endpoint=" + endpoint + "&rnd=" + currentTimestamp + "&accessToken=" + token;
	if(params != null)
		url += http_build_query(params);
	// console.log("url in getAPI(): ", url);
    return fetch(url,
    {
		//credentials: "include",
		headers: {
			Authorization: "Bearer " + token
		}
    })
    .then((response) => response.json())
    .then((responseData) => {
		if(responseData['error']) {
			console.log("API Error upon calling endpoint '", endpoint, "': ", responseData['error']);
			// alert("API Error upon calling endpoint: " + responseData['error']);
		}
		return responseData;
    })
    .catch(error => {
		console.log("error calling endpoint " + endpoint + ": ", error);
		// alert("error calling endpoint " + endpoint + ": " + error);
    });
	return null;
}

export function postAPI(endpoint: string, token: string, params: any) {
	const currentTimestamp = (new Date).getTime();
	const url = baseURI + "restapi.php?endpoint=" + endpoint + "&rnd=" + currentTimestamp + "&accessToken=" + token;
    return fetch(url,
	{
		method: "post",
		credentials: "include",
		headers: {
			"Content-Type": "application/json",
			// "Connection": "keep-alive",
			"Authorization": "Bearer " + token
		},
		body: JSON.stringify(params),
	})
	.then((response) => response.json())
	.then((responseData) => {
		console.log("Calling POST API Endpoint '" + endpoint + "' with body: ", params, ", result: ", responseData);
		if(responseData.success == undefined) {
			// alert("API Error upon calling endpoint: " + responseData['error']);		
		}
		return responseData;
	})
    .catch(error => {
		console.log("error calling endpoint " + endpoint + ": ", error);
		alert("error calling endpoint " + endpoint + ": " + error);
    });
}


export function http_build_query(obj: any, separator = '&')
{
	if(separator == undefined) separator = '&';
	let queryString = "";
	for(const key in obj)
	{
		// separator = separator ? "&" : "?"; 
		queryString += (separator + key + "=" + obj[key]);
	}
	return queryString;
}

export function setStorageData(name: string, data: any) {
	// sessionStorage.setItem(name, JSON.stringify(data));
	localStorage.setItem(name, JSON.stringify(data));
}

export function getStorageData(name: any) {
	// var data = JSON.parse(sessionStorage.getItem(name));
	const data = JSON.parse(localStorage.getItem(name));
	return data;
}

export function removeStorageData(name: string) {
	// sessionStorage.setItem(name, JSON.stringify(data));
	localStorage.removeItem(name); 
}

export function getFormattedDate(objDate) {
	const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	const strDayOfWeek = weekDays[objDate.getDay()];
	let strDayOfMonth = objDate.getDate();
	if(strDayOfMonth < 10)
		strDayOfMonth = "0" + strDayOfMonth;
	
	let strMonth = objDate.getMonth() + 1;
	if(strMonth < 10)
		strMonth = "0" + strMonth;
	
	const strYear = objDate.getFullYear();
	
	const strDate = strDayOfWeek + ", " + strDayOfMonth + "/" + strMonth + "/" + strYear;
	return strDate;
}

export function isCurrentDate(strDate) {
	const strCurrentDate = getFormattedDate(new Date());
	return strDate == strCurrentDate;
}