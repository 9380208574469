






























import Vue from "vue";
import DashboardCard from "./DashboardCard.vue";
import { getAPI, getStorageData } from "../../util";

	
export default Vue.extend({
  components: {
    DashboardCard,
  },
  data() {
    return {
		autoRefresh: true,
		interval: null,
		timeInterval: 30000,
		lastId: 0,
		limit: 50,
		offset: 0,
		userRole: null,
		loading: true,
		audio: null,
		mounted: false,
    };
  },
	beforeCreate() {
		// alert("beforeCreate() called");
		// this.checkAndRefreshData();
	},
	created() {
		// alert("created() called");
		// this.checkAndRefreshData();
	},
	beforeMount(){
		// console.log("beforeMount() called");
		// alert("beforeMount() called");
		this.checkAndRefreshData();
	},
	mounted() {
		// alert("mounted() called");
		this.mounted = true;
		/*setTimeout(() => {
		  this.loading = false;
		}, 1000);*/
	},
	beforeUpdate() {
		// alert("beforeUpdate() called");
		// console.log("beforeUpdate() called - this.mounted: ", this.mounted);
		// if(!this.mounted)
		// 	this.checkAndRefreshData();
	},
	updated() {
		// alert("updated() called");
		// console.log("updated() called");
	},
	beforeDestroy() {
		// console.log("Dashboard::beforeDestroy() - ");
		clearInterval(this.interval);
	},
	props: {
		diaryCount: [Number, Number],
		correspondenceCount: [Number, Number],
		gradesCount: [Number, Number],
		selectedStudentId: [String, Number],
	},
  methods: {
	refreshData() {
		// Samee - Check to see if user info exists in local storage
		const user = getStorageData('user');
		// alert("refreshData() called - Dashboard view - user info fetched - user['role']: " + user['role'] + ", user['id']: " + user['id'] + ", user['accessToken']: " + user["accessToken"]);
		
		if(user != null) {
			let tmpLastId = 0; // this.lastNotificationId != null ? this.lastNotificationId : 0;
			
			const year = 2021;
			// Fetch dashboard info
			getAPI("notificationsList", user["accessToken"], {"year": year, "userId": user['id'], "userRole": user['role'], "limit": this.limit, "offset": this.offset}).then(response => {
				// this.audio = new Audio('http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3');
				// alert("response: " + response);
				this.loading = false;
				if(response != null) {
					let data = JSON.parse(JSON.stringify(response.data));
					// console.log("dashboard response: ", data);
					// if accessToken has expired, return to login page
					// alert("response.data: " + data);
					if(response['error'] != null) {
						// alert("error: " + response['error']);
						this.$router.replace("/login");
					} else {
						// Samee - why?
						//if(data.length == 0)
						//	this.$router.replace("/login");
						this.$emit('dashboard-loaded', data);
					} 
				} else {
					// this.$router.replace("/login");
					window.location.reload();
				}
			});
		} else {
			// alert("refreshData() - user is null, returning to login...");
			this.$router.replace("/login");
		}
	},
	checkAndRefreshData() {
		
		const user = getStorageData('user');
		// alert("checkAndRefreshData() called - user: " + user);
		if(user == null) {
			// alert("checkAndRefreshData() - user is null, returning to login...");
			this.$router.replace("/login");
			return;
		} else {
			// alert("checkAndRefreshData() - user['role']: " + user['role'] + ", user['id']: " + user['id'] + ", user['accessToken']: " + user["accessToken"]);
			this.userRole = user['role'];
			this.refreshData();

			const interval = setInterval(async () => {
				if(this.autoRefresh) { 
					// console.log("Order::componentDidMount() - refreshData being called....");
					this.refreshData();	  
				}
			}, this.timeInterval);
			this.interval = interval;
		}
	},
  },
  
});
window.onpageshow = function(event) {
	// alert("location: " + document.location + ", state: " + JSON.stringify(event.state));
	// this.checkAndRefreshData();
	event.preventDefault();
};
