















// import { Message } from "@/data/Conversation";
import Vue from "vue";
export default Vue.extend({
	data() {
		return {
			assignment: {},
		}
	},
	beforeMount() {
		console.log("this: ");
	},
	props: {
		message: {
			type: Object,
			required: true,
		},
		fromSelf: {
			type: Boolean,
			required: true,
		},
	},
});
