




















































































// import sampleAssignments from "@/data/assignment/SampleAssignments";
import Vue from "vue";
import AssignmentStatusChip from "../../../components/assignment/AssignmentStatusChip.vue";
import AssignmentDownloadButton from "../../../components/assignment/AssignmentDownloadButton.vue";
import { getAPI, getStorageData, postAPI, getFormattedDate  } from "../../../util";

export default Vue.extend({
	components: { AssignmentStatusChip, AssignmentDownloadButton },
	data() {
		return {
			assignment: {},
			btnSendLoading: false,
			newMessage: '',
			user: {},
			loading: true,
		}
	},
	props: {
	},
	beforeMount() {
		const user = getStorageData('user');
		// console.log("user: ", user);
		this.user = user;
		var appUserNotificationId = this.$route.params.id;
		// console.log("AssignmentDetails::beforeMount() - appUserNotificationId: ", appUserNotificationId);
		getAPI("diaryDetails", user["accessToken"], {"appUserNotificationId": appUserNotificationId}).then(response => {
			this.loading = false;
			let data = JSON.parse(JSON.stringify(response.data));
			// console.log("AssignmentDetails::beforeMount() - data: ", data);
			if(response['error'] != null) {
				this.$router.replace("/login");
			} else {
				this.assignment = data;
			} 
		});
	},
	methods: {
		getDateString() {
			return this.assignment.dueDate.toLocaleDateString("en-US", {
			year: "numeric",
			month: "short",
			day: "numeric",
			});
		},
		onClickBack() {
			this.$router.go(-1);
		},
		getURL(senderName) {
			return "https://ui-avatars.com/api/?name=" + senderName.split(' ').join('+');
		},
		onSendClicked() {
			this.btnSendLoading = true;
			// console.log("send clicked, newMessage: ", this.newMessage, ", correspondenceId: ", this.correspondenceId);
			const tmpMessage = this.newMessage;
			this.newMessage = "";
			const user = getStorageData('user');
			postAPI("sendComment", user["accessToken"], {"appDiaryId": this.assignment.appDiaryId, "message": tmpMessage}).then(response => {
				if(response.success) {
					let tmpComments = this.assignment.comments;
					tmpComments.push({
						"message": tmpMessage, 
						"senderId": user["id"], 
						"senderName": user["firstname"], 
						"date": getFormattedDate(new Date())
					});
					this.assignment.comments = tmpComments;
				}
				this.btnSendLoading = false;
			});
			
		},
	},
	computed: {
		completeURL() {
			return "/app/uploaded/" + this.assignment.attachment;
		},
		completeURL2() {
			return "/app/uploaded/" + this.assignment.attachment2;
		},
		btnLabel1() {
			return "File 1";
		},
		btnLabel2() {
			return "File 2";
		}
		
	},
});
