
















import Vue from "vue";
// import sampleAssignments from "../../../data/assignment/SampleAssignments";
import AssignmentPreview from "./AssignmentPreview.vue";

export default Vue.extend({
	beforeMount() {
		// console.log("Homework::beforeMount() - this.assignments: " , this.assignments);
	},
	components: { AssignmentPreview },
	props: {
		assignments: Array,
	},
	methods: {
		btnReadMoreClicked(pAppUsersNotificationId) {
			this.$emit('read-more-clicked', pAppUsersNotificationId);
		}
	}
	/*computed: {
		assignments() {
			return this.$store.state.assignments;
		},
		data() {
			return {
				assignments: sampleAssignments,
			};
		},
	},*/
});
