




































import Vue from "vue";

export default Vue.extend({
  name: "App",

  data() {
    return {
		diaryCount: 0,
		correspondenceCount: 0,
		gradesCount: 0,
		totalCount: 0,
		notificationsList: [], // Fetched directly from the dashboard
		filteredNotificationsList: [], // Filtered notificationsList according to selected student
		unreadNotifications: [],
		selectedStudentId: 0,
		diaryList: [],
		correspondenceList: [],
		filteredDiaryList: [],
		classworkList: [],
		homeworkList: [],
		noticesList: [],
		viewType: "dashboard",
    };
  },
  methods: {
	dashboardLoaded(pNotificationsList) {
		this.notificationsList = pNotificationsList;
		this.viewType = "dashboard";
		this.studentSelected(this.selectedStudentId);
		
	},
	diaryLoaded(pDiaryList) {
		// console.log("this.diaryList: ", this.diaryList);
		/*
		// Append to diary list
		let tmpDiaryList = this.diaryList;
		tmpDiaryList.push(...pDiaryList);
		this.diaryList = tmpDiaryList;
		*/
		this.diaryList = pDiaryList;
		this.viewType = "diary";
		this.studentSelected(this.selectedStudentId);
	},
	correspondenceLoaded(pCorrespondenceList) {
		// console.log("correspondenceLoaded() - pCorrespondenceList: ", pCorrespondenceList);
		this.correspondenceList = pCorrespondenceList;
	},
	studentSelected(pStudentId) {
		console.log("studentSelected() - viewType: ", this.viewType, ", pStudentId: ", pStudentId);
		this.selectedStudentId = pStudentId;
		switch(this.viewType) {
			case "dashboard":
				
				this.filteredNotificationsList = pStudentId > 0  ? this.notificationsList.filter(row => row.studentId == pStudentId) : this.notificationsList;
				
				var unreadNotifications = this.filteredNotificationsList.filter(row => row.bRead == '0');
				this.unreadNotifications = unreadNotifications;
				this.totalCount = unreadNotifications.length;
				this.diaryCount = unreadNotifications.filter(row => row.type == '1').length;
				this.correspondenceCount = unreadNotifications.filter(row => row.type == '6').length;
				break;
				
			case "diary":
				this.filteredDiaryList = pStudentId > 0 ? this.diaryList.filter(row => row.studentId == pStudentId) : this.diaryList;
		
				unreadNotifications = this.filteredDiaryList.filter(row => row.bRead == '0');
				this.totalCount = unreadNotifications.length;
				this.unreadNotifications = unreadNotifications;
				
				this.classworkList = this.filteredDiaryList.filter(row => row.diaryType == 'cw');
				this.homeworkList = this.filteredDiaryList.filter(row => row.diaryType == 'hw');
				this.noticesList = this.filteredDiaryList.filter(row => row.diaryType != 'cw' && row.diaryType != 'hw');
				
				break;
		}

	},
	refreshClicked() {
		// console.log('App - refreshClicked() called!');
		this.studentSelected(this.selectedStudentId);
	},
	btnReadMoreClicked(pAppUsersNotificationId) {
		// console.log("App::btnReadMoreClicked() - pAppUsersNotificationId: ", pAppUsersNotificationId);
		if(this.totalCount > 0)
			this.totalCount--;
		// Find index,
		const i = this.filteredDiaryList.findIndex(row => row.id == pAppUsersNotificationId);
		// console.log("this.filteredDiaryList: ", this.filteredDiaryList, "pAppUsersNotificationId: ", pAppUsersNotificationId, ", i:  ", this.filteredDiaryList);
		// this.filteredDiaryList[i]['bRead'] = '1';
		// this.unreadNotifications = this.filteredDiaryList.filter(row => row.bRead == '0');
		this.unreadNotifications = this.unreadNotifications.filter(row => row.id != pAppUsersNotificationId);
		// console.log("this.unreadNotifications: ", this.unreadNotifications);
	},
	conversationOpened(pNumMessagesRead) {
		console.log("App::conversationOpened() - pNumMessagesRead: ", pNumMessagesRead);
		this.totalCount = this.totalCount >= pNumMessagesRead ? (this.totalCount - pNumMessagesRead) : 0;
	},
	onLogoutClicked() {
		// console.log("App::onLogoutClicked()");
		this.diaryCount = 0;
		this.correspondenceCount = 0;
		this.gradesCount = 0
		this.totalCount = 0;
		this.notificationsList = [];
		this.filteredNotificationsList = [];
		this.selectedStudentId = 0;
		this.diaryList = [];
		this.filteredDiaryList = [];
		this.classworkList = [];
		this.homeworkList = [];
		this.noticesList = [];
		
		this.correspondenceList = [];
		this.viewType = "dashboard";
	}
  }
});
