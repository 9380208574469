
















import Vue from "vue";
import { useSound } from '@vueuse/sound'
// import chord from "@/data/chord.wav"
export default Vue.extend({
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    route: {
      type: String,
    },
    badgeContent: [String, Number],
	audio: Audio,
  },
  methods: {
    cardClicked() {
      // let chord = useSound('http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3', { volume: 2.5 });
      // chord.play();
      // this.audio.play();
      // playSound('http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3');
      if (this.route) {
        this.$router.push(this.route);
      }
    },
  },
});
