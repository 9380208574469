import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import MainAppBar from "../components/MainAppBar.vue";
import Login from "../views/login/Login.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import DashboardBottomNavigation from "../views/dashboard/DashboardBottomNavigation.vue";
import Diary from "../views/diary/Diary.vue";
import DiaryBottomNavigation from "../views/diary/DiaryBottomNavigation.vue";

import AssignmentDetails from "../views/diary/assignment-details/AssignmentDetails.vue";
import AssignmentDetailsAppBar from "../views/diary/assignment-details/AssignmentDetailsAppBar.vue";
// import TeacherCorrespondence from "../views/teacher-correspondence/TeacherCorrespondence.vue";

import Correspondence from "../views/correspondence/Correspondence.vue";
import CorrespondenceBottomNavigation from "../views/correspondence/CorrespondenceBottomNavigation.vue";
import ConversationView from "../views/correspondence/conversation/ConversationView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "Login",
    components: {
      default: Login,
      // appBar: LoginAppBar, // Not needed
    },
  },
  {
	// ToDos: Setting Dashboard component as the default
    path: "/",
    name: "Dashboard",
    components: {
      default: Dashboard,
      appBar: MainAppBar,
      bottomNavigation: DashboardBottomNavigation,
    },
    meta: {
      showBottomNavigation: true,
    },
  },
  {
    path: "/diary",
    name: "Diary",
    components: {
      default: Diary,
      appBar: MainAppBar,
      bottomNavigation: DashboardBottomNavigation,
    },
    meta: {
      showBottomNavigation: true,
    },
  },
  {
    path: "/diary/assignment/:id",
    name: "Assignment",
    components: {
		default: AssignmentDetails,
		// appBar: AssignmentDetailsAppBar,
		appBar: MainAppBar,
		bottomNavigation: DashboardBottomNavigation,
    },
  },
  {
    path: "/correspondence",
    name: "Correspondence",
    components: {
      default: Correspondence,
      appBar: MainAppBar,
      bottomNavigation: DashboardBottomNavigation,
    },
    meta: {
      showBottomNavigation: true,
    },
  },
  {
    path: "/correspondence/:id",
    name: "ConversationView",
    components: {
      default: ConversationView,
      appBar: MainAppBar,
      bottomNavigation: DashboardBottomNavigation,
    },
    meta: {
      showBottomNavigation: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
