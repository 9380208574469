import Assignment, {
  AssignmentDownload,
  AssignmentDownloadType,
  AssignmentStatus,
} from "@/data/assignment/Assignment";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    assignments: [
      new Assignment(
        "Social Studies",
        "Read The Maltese Falcon",
        "Read Chapter 5 of The Maltese Falcon and complete the reading questions in the attached PDF document.",
        new Date("Jan 28, 2021"),
        AssignmentStatus.Overdue,
        new AssignmentDownload(
          AssignmentDownloadType.Pdf,
          new URL("http://google.com")
        )
      ),
      new Assignment(
        "Math",
        "Algebra Problems 1-5",
        "Complete the practice science activity specified in CH 2 of the textbook.",
        new Date("Jan 28, 2021"),
        AssignmentStatus.SubmittedLate
      ),
      new Assignment(
        "English",
        "Answer Discussion Question",
        "Complete questions 10-30 in the algebra workbook on page 51, and question 20-25 on page 66.",
        new Date("Jan 28, 2021"),
        AssignmentStatus.Submitted,
        new AssignmentDownload(
          AssignmentDownloadType.Doc,
          new URL("http://google.com")
        )
      ),
      new Assignment(
        "Social Studies",
        "Finalize Essays",
        "Write a 2 page essay on the history of the French revolution. This should include an analysis of important events and the Quisquam delectus vel nulla voluptatem. Dicta quia dolores similique velit quia corrupti maxime incidunt. Modi nostrum ut ab commodi est sapiente accusamus.",
        new Date("Jan 28, 2021"),
        AssignmentStatus.Upcoming
      ),
    ],
  },
  mutations: {},
});
