
































































import Vue from "vue";
import { postAPI, basePath, getStorageData } from "../../util";
export default Vue.extend({
  props: {
  },
  methods: {
    refreshClicked() {
     this.$emit('refresh-clicked');
    },
	onSaveClicked() {
		console.log('onSaveClicked()');
		const year = 2021;
		if(this.password != "" && this.password != this.confirmPassword) {
			this.error = "Passwords do not match";
		} else {
			postAPI("saveUserInfo", getStorageData('user')["accessToken"], 
			{"password": this.password, "id": getStorageData('user')["id"]}).then(response => {
				if(response.success) {
					this.showSettingsDialog = false;
				}
			});
		}
	},
	onSettingsClicked() {
		// console.log('onSettingsClicked()');
		this.password = '';
		this.confirmPassword = '';
		this.error = null;
		this.showSettingsDialog = true;
	}
  },
  data() {
	return {
		helpURL: "help.html",
		mainURL: "/" + basePath,
		showSettingsDialog: false,
		password: '',
		showPassword: false,
		confirmPassword: '',
		showConfirmPassword: false,
		error: null,
	}
  }
});
