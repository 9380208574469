












































import Vue from "vue";
import {removeStorageData, getAPI, getStorageData } from "../util";

export default Vue.extend({
	data() {
		return {
			user: {},
			
		};
	},
	beforeMount(){
		// Samee - Check to see if user info exists in local storage
		const user = getStorageData('user');
		this.user = user;
		// console.log("StudentSelect - user: ", this.user);
	},
	props: {
		selectedStudentId: [String, Number],
	},
	methods: {
		logoutClicked() {
			// console.log("logout clicked!");
			getAPI("logout", null, null).then(response => {
				// console.log("logout response: ", response);
				removeStorageData("user");
				this.$router.replace("/login");
				this.$emit('logout-clicked');
			});
		},
		studentClicked(studentId) {
			this.$emit('student-selected', studentId);
		},
		studentIconURL(pName) {
			const url = "https://ui-avatars.com/api/?name=Yahya+Qazi"; // + pName;
			console.log("studentIconURL: ", url);
			return url;
		}
	},
	computed: {
		userFullName() {
			return this.user.firstname + ' ' + this.user.lastname;
		},
		bgColorClass() {
			return 'highlighted'; // this.assignment.bRead == 0 ? 'highlighted' : '';
		},
		
	}
	
});
