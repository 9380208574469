












































import Vue from "vue";

// import { sampleConversations } from "../../../data/Conversation";
import MessageBubble from "./MessageBubble.vue";
import { getAPI, getStorageData, postAPI, getFormattedDate } from "../../../util";

export default Vue.extend({
	components: {
		MessageBubble,
	},
	data() {
		return {
			messages: [],
			subject: '',
			correspondenceDate: '',
			correspondenceId: null,
			dateEnded: null,
			userId: null,
			newMessage: '',
			interval: null,
			timeInterval: 30000,
			autoRefresh: true,
			btnSendLoading: false,
			loading: true,
		}
	},
	beforeMount() {
		this.refreshData();

		const interval = setInterval(async () => {
			if(this.autoRefresh) { 
				this.refreshData();	  
			}
		}, this.timeInterval);
		this.interval = interval;
	},
	beforeDestroy() {
		// console.log("Diary::beforeDestroy() - ");
		clearInterval(this.interval);
	},
	methods: {
		getConversation() {
			// return this.messages;
		},
		refreshData() {
			const user = getStorageData('user');
			var appCorrespondenceId = this.$route.params.id;

			let tmpLastId = 0; // this.messages != null && this.messages.length > 0 ? this.messages[this.messages.length - 1]['id'] : 0;
			getAPI("correspondenceMessages", user["accessToken"], {"appCorrespondenceId": appCorrespondenceId}).then(response => {
				this.loading = false;
				let data = JSON.parse(JSON.stringify(response.data));
				// console.log("AssignmentDetails::beforeMount() - data: ", data);
				if(response['error'] != null) {
					this.$router.replace("/login");
				} else {

				/*
					let newMessages = response.data.messages;
					// Append newMessages to this.messages
					let tmpMessages = this.messages;
					tmpMessages.push(...newMessages);
					this.messages = tmpMessages;
					*/
					this.messages = response.data.messages;
					this.subject = response.data.correspondenceTitle;
					this.correspondenceDate = response.data.correspondenceDate;
					this.correspondenceId = response.data.correspondenceId;
					this.dateEnded = response.data.dateEnded;
					this.userId = user["id"];
					
					// window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
				} 
			});
		},
		onSendClicked() {
			this.btnSendLoading = true;
			// console.log("send clicked, newMessage: ", this.newMessage, ", correspondenceId: ", this.correspondenceId);
			const tmpMessage = this.newMessage;
			this.newMessage = "";
			const user = getStorageData('user');
			postAPI("sendMessage", user["accessToken"], {"appCorrespondenceId": this.correspondenceId, "message": tmpMessage}).then(response => {
				if(response.success) {
					this.messages.push({
						"message": tmpMessage, 
						"senderId": user["id"], 
						"senderName": user["firstname"], 
						"date": getFormattedDate(new Date())
					});
				}
				this.btnSendLoading = false;
			});
			
		},
		onCloseConversationClicked() {
			const user = getStorageData('user');
			getAPI("endCorrespondence", user["accessToken"], {"appCorrespondenceId": this.correspondenceId}).then(response => {
				this.$router.push(`/correspondence`);
			});
		},
		onReopenConversationClicked() {
			const user = getStorageData('user');
			getAPI("reopenCorrespondence", user["accessToken"], {"appCorrespondenceId": this.correspondenceId}).then(response => {
				this.$router.push(`/correspondence`);
			});
		},
	},
	computed: {
		
	}
});
