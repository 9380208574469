





































import Vue from "vue";
import StudentSelect from "./StudentSelect.vue";
// import BellIconSelect from "./BellIconSelect.vue";

export default Vue.extend({
	data() {
		return {
			selectedStudentId: 0,
		};
	},
	beforeMount(){
		// console.log("MainAppBar - beforeMount called - getTotalCount(): ");
	},
	// components: { StudentSelect, BellIconSelect },
	components: { StudentSelect},
	props: {
		totalCount: [String, Number],
		unreadNotifications: [Array],
	},
	methods: {
		backClicked() {
			this.$router.go(-1);
		},
		studentSelected(pStudentId) {
			// console.log("MainAppBar - studentSelected called! ", pStudentId);
			this.selectedStudentId = pStudentId;
			this.$emit('student-selected', pStudentId);
		},
		onLogoutClicked() {
			this.$emit('logout-clicked');
		},
		onBellIconClicked() {
			console.log("onBellIconClicked() called!");
		},
		btnReadMoreClicked(pAppUsersNotificationId) {
			this.$emit('read-more-clicked', pAppUsersNotificationId);
		}
	},
	computed: {
		
	},
});
