


























































































































import ConversationPreview from "./ConversationPreview.vue";
import { getAPI, getStorageData, postAPI } from "../../util";

import Vue from "vue";
export default Vue.extend({
	data() {
		return {
			tab: this.$route.query.tab,
			autoRefresh: true,
			interval: null,
			timeInterval: 30000,
			btnSendLoading: false,
			showNewConversationDialog: false,
			classId: null,
			contact: null,
			student: null,
			subject: '',
			message: '',
			classesList: [],
			contactsList: [],
			// conversations: [],
			userRole: '',
			loading: true,
			studentId: 0,
			parentId: 0,
		};
	},
	components: {
		ConversationPreview,
	},
	beforeMount() {
		const user = getStorageData('user');
		this.userRole = user['role'];
		this.userStudents = user['students'];
		this.refreshData();

		const interval = setInterval(async () => {
			if(this.autoRefresh) { 
				this.refreshData();	  
			}
		}, this.timeInterval);
		this.interval = interval;
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	computed: {
		
	},
	props: {
		conversations: Array,
		selectedStudentId: [String, Number],
	},
	methods: {
		getConversation(conversation) {
			const user = getStorageData('user');
			if((user['role'] == 'teacher' || user['role'] == 'classteacher') && conversation.studentName != null)
				conversation.contactName = conversation.studentName;
			
			return conversation;
		},
		refreshData() {
			if(!this.showNewConversationDialog) {
				// Samee - Check to see if user info exists in local storage
				const user = getStorageData('user');
				
				if(user != null) {
					const year = 2021;
					// Fetch correspondences list
					getAPI("correspondencesList", user["accessToken"], {"year": year, "userId": user['id']}).then((response: any) => {
						this.loading = false;
						let data = JSON.parse(JSON.stringify(response.data));
						if(response['error'] != null) {
							this.$router.replace("/login");
						} else {
							this.$emit('correspondence-loaded', data);						
						}
					});
				} else {
					this.$router.replace("/login");
				}
			}
		},
		onSendClicked() {
			const user = getStorageData('user');
			let selectedStudentId = 0;
			if(user.role == 'parent') {
				if(this.selectedStudentId > 0) {
					selectedStudentId = this.selectedStudentId;
				}
			} else {
				selectedStudentId = this.studentId;
			}
			this.btnSendLoading = true;
			const year = 2021;

			let contactId = user.role != 'parent' ? this.parentId : this.contact;
			// console.log("contactId to be saved: ", contactId);
			postAPI("startCorrespondence", getStorageData('user')["accessToken"], 
			{"year": year, "title": this.subject, "message": this.message, "contactId": contactId, "studentId": this.studentId}).then(response => {
				const appCorrespondenceId = response["data"]["appCorrespondenceId"];
				this.showNewConversationDialog = false;
				this.btnSendLoading = false;
				this.$router.push(`/correspondence/${appCorrespondenceId}`);
			});
			
		},
		onClassChanged() {
			const user = getStorageData('user');
			getAPI("classStudentsList", user["accessToken"], {"classId": this.classId}).then((response: any) => {
				this.contactsList = response['data'];
			});
		},
		onContactChanged() {
			const user = getStorageData('user');
			if(user.role != 'parent') {
				const selectedContact = this.contactsList.filter(row => row.id == this.contact)[0];
				this.studentId = selectedContact != null ? selectedContact.studentId : 0;
				this.parentId = selectedContact != null ? selectedContact.parentId : 0;
			}
		},
		onStudentChanged() {
			this.studentId = this.student;
		},
		onNewConversationClicked() {
			const user = getStorageData('user');
			const year = 2021;
			this.classId = 0;
			this.contact = 0;
			
			this.subject = '';
			this.message = '';
			this.showNewConversationDialog = true;
			this.btnSendLoading = false;
			if(user.role == 'parent') {
				if(this.userStudents.length == 1) {
					this.student = this.userStudents[0]['id'];
					this.studentId = this.userStudents[0]['id'];
				} else {
					this.student = 0;
				}
			
				if(this.contactsList.length == 0) {
					getAPI("contactsList", user["accessToken"], {"year": year}).then((response: any) => {
						this.contactsList = response['data'];
					});
				}
			} else {
				if(this.classesList.length == 0) {
					getAPI("teachersClassList", user["accessToken"], {"year": year}).then((response: any) => {
						this.classesList = response['data'];
					});
				}
			}
		},
		conversationOpened(numMessagesRead) {
			this.$emit('conversation-opened', numMessagesRead);
		}
	},
});
