









































import Vue from "vue";
// import Assignment from "../../../data/assignment/Assignment";
import AssignmentStatusChip from "../../../components/assignment/AssignmentStatusChip.vue";
// import AssignmentDownloadButton from "../../../components/assignment/AssignmentDownloadButton.vue";
import { isCurrentDate } from "../../../util";

export default Vue.extend({
	components: { AssignmentStatusChip },
	props: {
		assignmentId: {
			type: Number,
			required: true,
		},
		assignment: {
			type: Object,
			required: true,
		},
	},
	methods: {
		btnReadMoreClicked() {
			if(this.assignment.bRead == 0)
				this.$emit('read-more-clicked', this.assignment.id);
			this.$router.push("/diary/assignment/" + this.assignment.id);
		}
	},
	computed: {
		dateString() {
			/*
			
			var dateDue = this.assignment.dateDue ? this.assignment.dateDue : this.assignment.date;
			return dateDue.toLocaleDateString("en-US", {
				year: "numeric",
				month: "short",
				day: "numeric",
			});
			*/
			return isCurrentDate(this.assignment.date) ? "TODAY" : this.assignment.date;
		},
		bgColorClass() {
			return this.assignment.bRead == 0 ? 'highlighted' : '';
		},
		dateClass() {
			return isCurrentDate(this.assignment.date) ? "todaysPost text-right" : "text-right";
		}
	},
});
